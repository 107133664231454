import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import { Icon } from '@components/icons';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Layout } from '@components';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StyledPostContainer = styled.main`
  max-width: 1000px;
`;
const StyledPostHeader = styled.header`
  margin-bottom: 50px;
  .tag {
    margin-right: 10px;
  }
`;
const StyledPostContent = styled.div`
  margin-bottom: 100px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2em 0 1em;
  }

  p {
    margin: 1em 0;
    line-height: 1.5;
    color: ${props => props.theme.lightslate};
  }

  a {
    ${({ theme }) => theme.mixins.inlineLink};
  }

  code {
    background-color: #454647;
    color: ${({ theme }) => theme.white};
    border-radius: var(--border-radius);
    font-size: var(--fz-sm);
    padding: 0.2em 0.4em;
  }

  pre code {
    background-color: transparent;
    padding: 0;
  }

  .pdf_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 700px;
    height: 100%;
  }

  .pdf_controler_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  span {
    font-size: 0.8em;
    padding: 0 0.5em;
  }

  p {
    text-align: center;
    font-size: 0.8em;
    padding: 0;
    vertical-align: middle;
    display: table-cell;
  }

  .pdf_controler_button {
    background: transparent;
    color: white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
    border: 0;
    border-radius: 0;
    width: 44px;
    height: 44px;
    border: 0;
    font: inherit;
    font-size: inherit;
    font-size: inherit;
    font-size: 0.8em;
    border-radius: 4px;
  }

  .pdf_controler_button:enabled:hover {
    cursor: pointer;
  }

  .pdf_controler_button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .pdf_controler_button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
const StyledPostSharing = styled.div`
  text-align: center;
  justify-content: space-between;
  svg {
    width: 40px;
    height: 40px;
    margin: 10px;
  }
  a {
    ${({ theme }) => theme.mixins.inlineLink};
  }
`;

const LoadingPageTemplate = () => (
  <StyledPostContent>
    <div
      style={{
        display: 'block',
        userSelect: 'none',
        width: '720px',
        height: '405px',
      }}></div>
  </StyledPostContent>
);

const PostTemplate = ({ location, pageContext }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [displayControllers, setDisplayControllers] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    setDisplayControllers(true);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const { title, date, tags, pdfName } = pageContext;
  const file = `/pdf/${pdfName}`;

  const url = encodeURIComponent(location.href);

  return (
    <Layout location={location}>
      <Helmet title={title} />

      <StyledPostContainer>
        <span className="breadcrumb">
          <span className="arrow">&larr;</span>
          <Link to="/blog">All posts</Link>
        </span>

        <StyledPostHeader>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <div>
              <h1 className="medium-heading">{title}</h1>
            </div>
            <div
              style={{
                width: '30px',
                height: '30px',
                marginTop: '12px',
              }}>
              <a href={file} target="_blank" aria-label="Download" rel="noreferrer">
                <Icon name="Download" />
              </a>
            </div>
          </div>

          <p className="subtitle">
            <time>
              {new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </time>
            <span>&nbsp;&mdash;&nbsp;</span>
            {tags &&
              tags.length > 0 &&
              tags.map((tag, i) => (
                <Link key={i} to={`/pensieve/tags/${kebabCase(tag)}/`} className="tag">
                  #{tag}
                </Link>
              ))}
          </p>
        </StyledPostHeader>

        <StyledPostContent>
          <div className="pdf_container">
            <div
            // style={{
            //   width: '700px',
            //   height: '393px',
            // }}
            >
              <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                <Page
                  pageNumber={pageNumber}
                  renderAnnotationLayer={false}
                  loading={LoadingPageTemplate}
                  onRenderSuccess={() => setDisplayControllers(true)}
                  width={700}
                />
              </Document>
            </div>
            {displayControllers && (
              <div className="pdf_controler_container">
                <div>
                  <button
                    type="button"
                    className="pdf_controler_button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}>
                    &#8249;
                  </button>
                </div>
                <div style={{ display: 'table', height: '44px' }}>
                  <p>
                    {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                  </p>
                </div>
                <div>
                  <button
                    type="button"
                    className="pdf_controler_button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}>
                    &#8250;
                  </button>
                </div>
              </div>
            )}
          </div>
        </StyledPostContent>

        <StyledPostSharing>
          <div>Thanks for the support! Share.</div>
          <div>
            <a
              href={`https://twitter.com/share?url=${url}&text=${title}`}
              aria-label="Twitter Share">
              <Icon name="Twitter" />
            </a>
            <a
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}&Title=${title}`}
              aria-label="Linkedin Share">
              <Icon name="Linkedin" />
            </a>
            <a
              href={`http://reddit.com/submit?url=${url}&title=${title}`}
              aria-label="Reddit Share">
              <Icon name="External" />
            </a>
          </div>
        </StyledPostSharing>
      </StyledPostContainer>
    </Layout>
  );
};

export default PostTemplate;

PostTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
};
